














































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';

@Component({
    name: 'gkkp-setting'
})
export default class CGkkpSetting extends Vue {

    @Prop({
        required: true,
        default: null
    })
    private regionProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private abpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private guProp!: any | null;

    @Prop({
        required: true,
        default: false
    })
    private editableProp!: boolean;
    

    private progress = 0;
    private checkedAll = false;

    private curGu: any | null  = null;
    private curRegion: any | null = null;
    private curAbp: any | null = null;

    private dataLst: any[] = [];


    private get editable() {
        return this.editableProp;
    }
    
    // ----- специфика
    private curSpf: number = 159;
    private spfLst = [159, 311];
    /*private curSpf: any | null = null;
    private spfBase: any[] = [];

    private get spfLst(): any[] {
        const res: any[] = [];
        for (const el of this.spfBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'spf'));
        }

        if (this.curSpf !== null) { this.curSpf = setNameLang(this.$i18n.locale, this.curSpf, 'spf'); }
        return res;
    }*/

    // ---------- ГККП ---------------------
    private gkkpBase: any[] = [];
    private curGkkp: any[] = [];

    private get gkkp(): any[] {
        const res: any[] = [];
        for (const el of this.gkkpBase) {
            let fl = false;
            for (let i = 0; i < this.dataLst.length; i++) {
                if (this.dataLst[i].gkkp.bin === el.bin) {
                    fl = true;
                    break;
                }
            }
            if (!fl) { res.push(setNameLang(this.$i18n.locale, el, 'bin')); }
        }
        return res;
    }

    // -------- формы ---------------
    private formMap = new Map([
        [159, ['01-159', '02-159', '03-159']],
        [311, ['311']]
    ]);

    private formLst: any[] = [];

    private curForm: any | null = null;

    // -----------------

    private async loadGkkp() {
        this.gkkpBase = [];
        this.curGkkp = [];
        if (!this.curGu) { return; }
        const url = `/api-py/get-kgkp-by-gu/${encodeURI(this.curGu.code)}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                // if (response.length) { this.curGkkp = [setNameLang(this.$i18n.locale, response[0], 'bin')]; }
                this.gkkpBase = response;
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки справочника КГГП', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки справочника КГГП', (error as Error).toString());
        }
    }

    /*private async loadSpf() {
        const params = {spf: [159, 311]}
        const url = `/api-py/dict-ebk-spf-by-code/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                if (response.length) { this.curSpf = setNameLang(this.$i18n.locale, response[0], 'spf'); }
                this.spfBase = response;
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки справочника специфик', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки справочника специфик', (error as Error).toString());
        }
    }*/

    private chgEvent(params: any) {
        this.curGu = params.curGu;
        this.curAbp = params.curAbp;
        this.curRegion = params.regionProp;
        this.loadData();
        this.loadGkkp();
    }

    private getFormLst() {
        this.curForm = null;
        this.formLst = [];
        const formLst = this.formMap.get(this.curSpf);
        if (formLst) { 
            this.formLst = formLst;
            if (this.curSpf === 159) { 
                this.curForm = '02-159'; 
            } else {
                this.curForm = this.formLst[0];
            }
        }
    }

    private mounted() {
        this.getFormLst();
        this.curGu = this.guProp;
        this.loadGkkp();
        this.curRegion = this.regionProp;
        this.curAbp = this.abpProp;
        this.loadData();
        //this.loadSpf();
        this.$watch('curSpf', () => {
            this.getFormLst();
        });
    }

    private async addRow() {
        if (!this.curAbp || !this.curRegion || !this.curGu) {
            return;
        }
        const saveArr = [];
        if (!this.curGkkp || !this.curGkkp.length || !this.curSpf || !this.curForm) {
            makeToast(this, 'danger', 'Добавить строку', 'Заполните все данные!');
            return;
        }
        for (const curG of this.curGkkp) {
            let fl = false;
            for (let i = 0; i< this.dataLst.length; i++) {
                const el = this.dataLst[i];
                if (el.region.code === this.curRegion.code && el.abp.abp === this.curAbp.abp && el.gu.code === this.curGu.code && el.gkkp.bin === curG.bin) {
                    makeToast(this, 'danger', 'Добавить строку', `ГККП ${curG.bin} уже существует!`);
                    fl = true;
                    break;
                }
            }
            if (!fl) {
                const obj = { region: this.curRegion, abp: this.curAbp, gu: this.curGu, gkkp: curG, spf: this.curSpf, form: this.curForm, checked: this.checkedAll };
                saveArr.push(obj);
                // this.dataLst.push(obj);
            }
        }
        await this.save(saveArr);
        this.dataLst = this.dataLst.concat(saveArr);

        this.dataLst.sort((a: any, b: any) => (a.gkkp.bin > b.gkkp.bin) ? 1 : -1);
        
        this.curGkkp = [];
    }

    private async save(arr: any) {
        if (!this.editable) {
            makeToast(this, 'warning', 'Сохранение', 'Доступ запрещён!');
            return;
        }
        const saveArr: any[] = [];
        for (const el of arr) {
            const obj = { abp: el.abp.abp, form: el.form, gu: el.gu.code, region: el.region.code, gkkp: el.gkkp.bin, spf: el.spf };
            saveArr.push(obj);
        }
        try {
            const url = '/api-py/save-budget-gkkp-spf';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveArr)
            });
            response = await response.json();
            let success = true;
            for (const r of response) {
                if (r.result !== 'success') {
                    success = false;
                    makeToast(this, 'danger', 'Сообщение', `Сохранение ${response[0].result}`);
                } else {
                    const rData = r.data.__data__;
                   for (let i = 0; i < arr.length; i++) {
                        const el = arr[i];
                        if (el.abp.abp === rData.abp && el.gu.code === rData.gu && el.region.code === rData.region && el.gkkp.bin === rData.gkkp && el.spf === rData.spf && el.form === rData.form) {
                            el.id = rData.id;
                            break;
                        }
                    }
                }
            }
            if (success) { 
                makeToast(this, 'success', 'Сообщение', 'Данные сохранены'); 
            } else {
                this.loadData();
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка сохранения', (error as Error).toString());
        }
    }

    private delRow(indx: number | null) {
        if (!this.editable) {
            makeToast(this, 'warning', 'Удаление', 'Доступ запрещён!');
            return;
        }
        if (indx!==null) {
            this.deleteData(this.dataLst[indx]);
            this.dataLst.splice(indx, 1);
        } else {
            for (let i=0; i < this.dataLst.length; i++) {
                if (this.dataLst[i].checked) {
                    this.deleteData(this.dataLst[i]);
                    this.dataLst.splice(i, 1);
                    i--;
                }
            }
        }
    }

    private async deleteData(obj: any) {
        if (obj.id === undefined) { return; }
        const id = { id: obj.id };

        try {
            const url = '/api-py/del-budget-gkkp-spf';
            let response: any = await fetch(url, {
                method: 'DELETE',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(id)
            });
            response = await response.json();
            if (response.result!=='success') {
                makeToast(this, 'danger', 'Ошибка удаления', response.data);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка удаления', (error as Error).toString());
        }
    }

    private async loadData() {
        this.dataLst = [];
        if (!this.curGu || !this.curAbp || !this.curRegion) {
            return;
        }
        this.progress = 30;
        let result = [];
        const params = { gu: this.curGu.code, abp: this.curAbp.abp, region: this.curRegion.code };
        try {
            result = await fetch(`/api-py/get-budget-gkkp-spf/${encodeURI(JSON.stringify(params))}`)
                .then(response => response.json());
            for (const el of result) {
                const obj = { id: el.id, abp: { abp: el.abp }, form: el.form, gkkp: setNameLang(this.$i18n.locale, el.gkkp, 'bin'), gu: { code: el.gu }, region: { code: el.region }, spf: el.spf };
                this.dataLst.push(obj);
            }
        } catch (error) {
            result = [];
            makeToast(this, 'danger', 'Ошибка загрузки данных', (error as Error).toString());
        } finally {
            this.progress = 100;
        }
    }

    private clkCheck(indx: number | null) {
        if (indx === null) {
            for (const el of this.dataLst) {
                this.$set(el, 'checked', this.checkedAll);
            }
            return;
        }
        const checked = this.dataLst[indx].checked;
        if (!checked && this.checkedAll) {
            this.checkedAll = false;
            return;
        }
        if (checked && !this.checkedAll) {
            let fl = false;
            for (let i = 0; i < this.dataLst.length; i++) {
                if (!this.dataLst[i].checked) {
                    fl = true;
                    break;
                 }
            }
            if (!fl) {
                this.checkedAll = true;
            }
        }
    }
}
