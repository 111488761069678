































































































import {Component, Vue} from 'vue-property-decorator';
import CGkkpSetting from '@/modules/budget-request/budget-gkkp/gkkp-settings.vue';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import store from '@/services/store';

@Component({
    components: {
        'gkkp-setting': CGkkpSetting,
        'c-budg-form-lst': CBudgetFormsList
    }
})
export default class CBudgetGkkp extends Vue {

    private budgetLevel: number[] = [];

    private curFormSelect: any = '/budget-gkkp';

    // ------------------ регион ------------------
    private regionBase: any[] = [];
    private curRegion: any | null = null;

    private get getRegion() {
        const result: any[] = [];
        for (const el of this.regionBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curRegion !== null && !this.curRegion.name) { this.curRegion = setNameLang(this.$i18n.locale, this.curRegion, 'code'); }
        return result;
    }

    private obl: string | null = null;

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private settingEnable = false;
    private settingEditable = false;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'abp'));
        }

        if (this.curAbp !== null && !this.curAbp.name) { this.curAbp = setNameLang(this.$i18n.locale, this.curAbp, 'abp'); }
        return res;
    }

    // --------- гос учреждение ------------
    private guBase: any[] = [];
    private curGu: any | null = null;

    private get gu(): any[] {
        const result: any[] = [];
        for (const el of this.guBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // ----------------------------------- loadDicts --------------------------------------


    private async getObl() {
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                this.obl = json.obl;
            } else {
                makeToast(this, 'danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        if (!this.usrId) { return; }

        try {
            await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) {
                        json.sort((a: any, b: any) => (a.code - b.code > 0) ? 1 : -1);
                        this.regionBase = json;
                    }

                });
        } catch (error) {
            this.regionBase = [];
            makeToast(this, 'danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }
        if (this.regionBase.length) { this.curRegion = setNameLang(this.$i18n.locale, this.regionBase[0], 'code') }
    }


    private getBudgetLevel() {
        this.budgetLevel = [];
        if (this.curRegion) {
            if (this.curRegion.code.slice(this.curRegion.code.length - 4) === '0101') {
                this.budgetLevel.push(2);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) === '01')  {
                this.budgetLevel.push(3);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) !== '00') {
                this.budgetLevel.push(4);
            }
        }
        this.abpBase = [];
        this.loadAbp();
    }

    private async loadAbp() {
        if (this.abpBase.length > 0) { return; }
        if (!this.budgetLevel || this.budgetLevel.length < 1) {
            this.curAbp = null;
            return;
        }
        const url = `/api-py/get-dict-ved-abp-by-budgetlevel-user/${encodeURI(JSON.stringify(this.budgetLevel))}/${this.usrId}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
    }

    private async loadGu() {
        if (!this.curAbp || !this.usrId) {
            this.guBase = [];
            return;
        }
        let result: any[] = [];
        this.curGu = null;
        if (this.curAbp !== null) {
            try {
                result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.curAbp.abp}/${this.usrId}`)
                    .then(response => response.json());
            } catch (error) {
                result = [];
                makeToast(this, 'danger', 'Ошибка загрузки гос. учреждений', (error as Error).toString());
            }
            if (result.length > 0) {
                this.curGu = setNameLang(this.$i18n.locale, result[0], 'code');
            } else {
                this.curGu = null;
            }
            this.guBase = result;
        }
    }
    /// --------------------------------------------------------------------------------

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private mounted() {
        this.getObl();
        this.getModulesAccess();

        this.$watch('curAbp', () => {
            this.loadGu();
            this.chgEvent('watch curAbp');
        });

        this.$watch('curRegion', async () => {
            this.getBudgetLevel();
        });
        this.$watch('curGu', () => {
            this.chgEvent('watch curGu')
        });
        this.$watch('store.state.user.userModules', () => { this.getModulesAccess(); });
    }

    private getModulesAccess() {
        this.settingEnable = false;
        const modules: any[] = store.state.user.userModules;
        for (const row of modules) {
            if (row.modules === '004.002.009.003') {
                if (row.access_level === 1) {
                    this.settingEditable = false;
                } else {
                    this.settingEditable = true;
                }
                if ([1, 2, 3].includes(row.access_level)){
                    this.settingEnable = true;
                }
            }
        }
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private chgEvent(source: string) {
        const params = { curAbp: this.curAbp, regionProp: this.curRegion, curGu: this.curGu };
        if (this.$refs.refSettings) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refSettings.chgEvent(params);
        }
    }
}
